// Definicje stylów do wyświetlania banera reklamowego


// Dla menu głównego (z lewej)
#main-navigation {
  div.banner {
    width: 100%;
    text-align: center;
    a {
      img {
        max-width: 100%;
      }
    }
  }
}