/**
 * Pomocniczy komponent linii czasu
 */

div.timeline {
  padding-left: 40px;
  position: relative;

  .line {
    height: 100%;
    position: absolute;
    top: 0;
    width: 1px;
    background-color: $regentStBlue;
    left: 13px;
  }

  .entry {
    padding: 40px;
    background: #fff;
    box-shadow: 0 0 10px 0 rgba(3,66,128,.1);
    border-radius: 10px;
    margin-bottom: 2.5rem;

    .header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 .8rem;

      h1 {
        font-size: 20px;
      }

      .date {
        color: $steelBlue;
        font-weight: $font-weight-bold;
      }

      .dot {
        width: 7px;
        height: 7px;
        background-color: $dodgerBlue;
        border-radius: 50%;
        position: absolute;
        left: -70px;
        top: 10px;
        z-index: 2;

        &:after {
          border-radius: 50%;
          display: block;
          content: "";
          width: 15px;
          height: 15px;
          border: 1px solid $regentStBlue;
          position: absolute;
          left: -4px;
          top: -4px;
        }
      }
    }

    .info {
      margin: 0 0 1rem;
    }

    .content {
      color: $blueZodiac;
      font-size: $font-size-lg;
    }

  }
}

.timeline {
  .event-content {
    overflow-x: auto;
  }
}