// Style związane z ekranami windykacji

.timeline {
  .entry {
    .file-info {
      font-size: $font-size-base;

      a {
        display: block;
      }
      span {
        color: $steelBlue;
      }
    }
  }
}

table.payment-alloc {
  width: 100%;

  td {
    border: none;
  }

  td.alloc-doc {

  }

  td.alloc-value {
    text-align: right;
    max-width: 5em;
  }
}

div.datatable.new-vindication-documents {
  margin-bottom: 5em; // miejsce na kalendarz
}

td span.creditor {
  display: block;
}

table.vindication-contacts {
  > tbody {
    > tr {
      > td {
        vertical-align: top;
        > div {
          padding-top: 0.5em;
        }
      }
    }
  }
}