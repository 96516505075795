// Ustawienia dla scenariusza

div.scenario-view-step {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  > div:not(:first-child) {
    padding-left: 5px;
  }

  > div:not(:last-child) {
    padding-right: 5px;
  }
}