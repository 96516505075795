// Style związane widokiem giełdy długów

.screen-debt-exchange-view {

  .alert.debtor-claims {
    border-radius: 10px;
    color: $secondary;

    .content div {
      font-size: $font-size-base;
    }

    .icon {
      font-size: $font-size-lg*1.2;
      height: 2em;
    }

    label {
      font-weight: $font-weight-normal;
    }

  }

  .alert.debtor-recovered {
    text-align: center;
    border-radius: 10px;

    &.alert-danger {
      background-color: $weak;
      color: white;
    }
  }

  .summary-values {

    .summary-total {
      margin-bottom: 1em;
      clear: both;
      .icon {
        float: left;
        font-size: $font-size-lg*1.2;
        height: 1em;
        margin-top: 1em;
      }

      > div {
        margin-left: 3em;
        label {
          display: block;
          font-size: $font-size-lg;
          font-weight: $font-weight-normal;
        }
        span {
          font-size: $font-size-lg*1.2;
          font-weight: $font-weight-bold;
        }
      }
    }

    div.left-right {
      label {
        font-weight: $font-weight-normal;
      }
    }
  }
}