// Style dla ekranu abonamentów dla modułu monitoringu faktur

div.subscription-packets {
  display: flex;
  flex-direction: row;
  gap: 1.5em;
  flex-wrap: wrap;
  justify-content: center;

  div.subscription-packet {
    flex: 1 1 0;
    min-width: 15em;
    max-width: 18em;

    div.card-body {
      display: flex;
      flex-direction: column;
      text-align: center;

      > * {
        padding-left: $card-spacer-x;
        padding-right: $card-spacer-x;
        margin-left: -$card-spacer-x;
        margin-right: -$card-spacer-x;

      }
    }

    div.header-part {
      min-height: 6.5rem;
    }

    div.buy-part {
      min-height: 4.5rem;
    }

    div.price-part {
      min-height: 6rem;

      p.price {
        font-size: $h3-font-size;
      }
    }

    div.info-part {
      min-height: 5rem;
    }

    div.footer-part {
      min-height: 3rem;
    }
  }
}

div.extra-packets {
  display: flex;
  flex-direction: row;
  gap: 1.5em;
  flex-wrap: wrap;
  justify-content: center;

  div.extra-packet {
    flex: 1 1 0;
    min-width: 15em;
    max-width: 18em;

    div.card-body {
      display: flex;
      flex-direction: column;
      text-align: center;

      > * {
        padding-left: $card-spacer-x;
        padding-right: $card-spacer-x;
        margin-left: -$card-spacer-x;
        margin-right: -$card-spacer-x;

      }
    }

    div.header-part {
      min-height: 3rem;
    }

    div.buy-part {
      min-height: 4.5rem;
    }

    div.price-part {
      min-height: 6rem;

      p.price {
        font-size: $h3-font-size;
      }
    }

    div.info-part {
      min-height: 5rem;
    }

    div.footer-part {

    }
  }
}

.price-select {
  cursor: pointer;
  color: white;

  background-color: $lima;
  transition: background-color 0.2s linear;

  padding: 15px 30px;

  &:hover {
    background-color: #2aa91e;
  }

  &.active {
    background-color: $strong;
  }

  div.price-info {
    font-size: $font-size-lg;
    display: block;
  }

  div.price-net, div.price-gross {
    font-size: $font-size-lg;

    span:first-child {
      width: 7em;
      display: inline-block;
    }
    span:last-child {
      width: 7em;
      display: inline-block;
      text-align: right;
    }
  }

}