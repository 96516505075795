div.im-desktop-counter {
  display: flex;

  > div:first-child {
  }

  > div:last-child {
    flex-grow: 1;
    align-self: center;

    > div {
      text-align: right;
      margin: 0.5rem;

      &.overdue {

      }

      &.before {
        color: gray;
      }

      span {
        font-size: $h3-font-size;
        display: block;
      }
    }

    > hr {
      border-top: 2px gray solid;
    }
  }
}

div.sync-info {
  &.sync-danger {
    > span {
      text-color: $danger;
    }
  }
}