@import "../../theme/client";

.filter {
  .filter-item {
    button {
      div {
        min-height: 1.5em;
        text-align: left;
      }
      p {
        margin: 0;
      }

      p:first-child {
        font-size: 0.6em;
        display: block;
      }
      p:last-child {
        font-size: 0.7em;
      }
    }

    &.show {
      p:first-child {
        font-size: 1em;
      }
    }
  }
}