// Style dla okienka z komunikatami z serwisu iMSiG.

div.imsig-entry {
  &:not(:first-child) {
    border-top: 1px solid $body-color;
    padding-top: 1rem;
    margin-top: 1rem;
  }

  div.imsig-entry-header {
    font-size: $h3-font-size;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  div.imsig-entry-content {
    background-color: #fff;
    padding: 0.5rem 1rem;
  }
}