

div.monitoring-scenario-info {
  min-height: 9rem;
  > div.spinner-border {
    margin-top: 2rem;
  }
}

.scenario-stage-header {
  border-bottom: 2px solid $primary;
  padding-top: 1rem;
  margin-bottom: 0.75rem;
}

table.monitored-counter {
  width: 100%;
  table-layout: fixed;

  tr {
    height: 1.5rem;
    > td.plan {
      color: #666;
    }
  }

  tr.sms-row, tr.date-row, tr.email-row {
    > td {
      text-align: center;
    }

    > td.label-cell {
      text-align: left;
      border-right: 1px solid #eee;
    }
  }

  tr.sms-row {
    > td {
      border-bottom: 1px solid #eee;
    }
  }
  tr.date-row {
    > td {
      text-align: center;
      border-bottom: 1px solid #ddd;
      white-space: nowrap;
    }
  }

  td.now {
    position: relative;

    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNsqAcAAYUBAdpOiIkAAAAASUVORK5CYII=);
    background-position: center;
    background-repeat: repeat-y;

  }
}

table {
  td:has(> div.stage-ExceedingScenario) {
    background-color: $danger !important;
    color: $white;
  }

  div.stage-Vindication {
    color: $primary;
  }
}

div.client-stage {
  .stage-ExceedingScenario {
    color: $danger;
  }
}