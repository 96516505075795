// klasa dla komponentu powiadomień

a.notification-entry {
  display: block;
  padding: .5rem 1rem .5rem 3rem;
  background-image: url(/resources/img/notification/notification.png);
  background-repeat: no-repeat;
  background-position: 5px 11px;

  div.date {
    font-size: $font-size-sm;
    color: $poloBlue;
    font-weight: $font-weight-normal;
  }
}

div.company-notifications {
  a.notification-entry {
    display: flex;

    div.content {
      flex-grow: 1;
    }
  }
}