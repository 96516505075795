// Style dla klienckiej części aplikacji
$mode-admin: false;
$mode-client: true;
$mode: "client";

// Konfiguracja dla Bootstrapa
// Importujemy wspólną konfigurację dla Bootstrapa
@import "common_variables";

@import "bootstrap/functions";
@import "bootstrap/variables";
// Kolory dla własnego rozszerzenia - kolor aktywnego obiektu
$theme-colors-active: (
        "primary":    $primary-active,
        "secondary":  darken($secondary, 10%),
        "success":    darken($success, 10%),
        "info":       darken($info, 10%),
        "warning":    darken($warning, 10%),
        "danger":     darken($danger, 10%),
        "light":      darken($light, 10%),
        "dark":       darken($dark, 10%)
);

$custom-control-indicator-checked-border-color: $custom-control-indicator-border-color;
$custom-control-indicator-active-border-color: $custom-control-indicator-border-color;

$breadcrumb-separator-color: #e0e0e0;

@import "bootstrap";

// Tu dodatkowe niezwiązane z Bootstrapem
@import "hamburger";
@import "components/webfonts";
@import "components/utils";

// Ustawienia własne dla szablonu
$menu-background: #feffff;
$menu-background-hover: #feffff;
$menu-color: $havelockBlue;
$menu-color-hover: $body-color;
$menu-logo-background: $white;

$top-menu-background: $blackSqueeze;

$main-background: #f2f7fa;

@import "components/icons";
@import "components/layout";
@import "components/menu";
@import "components/modal";
@import "components/inputs";
@import "components/react-select";
@import "components/other";
@import "components/login";
@import "components/tables";
@import "components/navs";
@import "components/datepicker";
@import "components/columns";
@import "components/card";
@import "components/dropzone";
@import "components/filter";
@import "components/timeline";
@import "components/status";
@import "components/progressbar";
@import "components/alert";
@import "components/fonts";
@import "components/rights";
@import "components/notification";
@import "components/progress-button";
@import "components/quill-view";
@import "components/banner";
@import "components/react-image-lightbox";
@import "components/html-epu";
@import "components/captcha";
@import "components/scenario";
@import "components/cursor";
@import "components/chart";
@import "components/invoice-monitoring";
@import "components/hint";
@import "components/im-preview";
@import "components/circle-progress";
@import "components/grid";

@import "fragments/debt_exchange";
@import "fragments/vindications";
@import "fragments/register";
@import "fragments/imsig";
@import "fragments/im_subscription";
@import "fragments/im-desktop";

// Ikony do menu klienckiego i administracyjnego są przygotowane na dwa różne sposoby stąd definicja poniżej
#main-navigation .accordion {
  > button, >a {
    &:hover, &.expanded {
      span {
        background-position: right top !important;
      }
    }
  }
}

#main-navigation {
  @include small-screen-up {
    &:after {
      content: "";
      position: absolute;
      width: 30px;
      height: 100%;
      right: -30px;
      top: 0;
      background: url(/resources/img/bg-menu.png) repeat-y;
    }
  }
}

.static-text-page {
  max-width: 62em;

  .static-content {
    font-size: $font-size-lg;
  }
}

.file-download {
  display: flex;
  margin: 0 0 1rem;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    color: $regentStBlue;
    background: linear-gradient(180deg,#e1f0f8 0,#fbfcfd);

    font-size: 20px;
    border-radius: 50%;
    margin: 0 1rem 0 0;
  }

  .data {
    margin: .4rem 0 0;

    .name {
      font-size: $font-size-lg;
    }

    .info {
      display: flex;
      margin: .2rem 0 0;
      font-size: $font-size-base;
      color: $regentStBlue;

      span:not(:first-child) {
        margin-left: 2em;
      }
    }
  }
}

.basic-header {
  min-height: 7em;
}

span.elapsed {
  color: $steelBlue;
}

.card.basic, .card.state {
  height: 100%;
}

.files {
  .image-files {
    display: flex;

    border-bottom: 1px solid $solitude;
    margin-bottom: 1em;
    padding-bottom: 1em;

    > div {
      margin: 1em;
      div.thumbnail {
        width: 256px;
        height: 256px;
        img {
          width: 256px;
          height: 256px;
          object-fit: contain;
        }
      }
      div.info {
        > * {
          display: block;
        }
      }
    }
  }

  .info {
    span.name {
      display: block;
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      word-break: break-all;
    }

    span.date {
      display: block;
      color: $steelBlue;
    }
  }

  @include media-breakpoint-up("md") {
    .other-files > div:nth-child(2n-1) {
      border-right: 1px solid $solitude;
    }
  }

  .file-info {
    border-bottom: 1px solid $solitude;
    padding-bottom: 1em;
    padding-top: 1em;
    font-size: $font-size-base;

    a {
      display: flex;
      align-items: center;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 31px;
        width: 31px;
        height: 31px;
        color: #a1c3e5;
        font-size: 16px;
        border-radius: 50%;
        margin: 0 1rem 0 0;
        background: #e1f0f8;
        background: linear-gradient(180deg,#e1f0f8 0,#fbfcfd);
      }

      .desc {
        display: block;
        font-weight: normal;

        .expandable {

        }
      }
    }
  }
}

.insurer-info {
  .icon {
    //width: 30px;
    //height: 30px;
    width: 3em;
    height: 2.5em;
    color: $steelBlue;
    margin-right: 0.5em;
  }

  .insurer-label {
    font-size: $font-size-sm;
  }
}

div.payment-entry {
  margin-bottom: 1em;
  padding-bottom: 1em;

  label {
    color: $regentStBlue;
    font-size: $font-size-sm;
  }

  div.value {
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $regentStBlue;
  }
}

div.receivable-entry {
  margin-bottom: 1em;
  padding-bottom: 1em;

  label {
    color: $regentStBlue;
    text-transform: uppercase;
    font-size: $font-size-sm;
    display: block;
  }

  div.value {
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $regentStBlue;
  }

}